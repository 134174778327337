import React from 'react';
import logo from '../../images/logo.png';
import styles from './footer.module.scss';
import { Col, Container, Row } from 'react-bootstrap';
import { Location } from '../../models';
import { useSelector } from 'react-redux';
import { getLocations } from '../../store/locations-store';
import { scrollToElement } from '../../utils';
import CustomExpandLess from '../../shared/control-arrows/custom-expand-less.component';

const Footer = () => {
  const copyrights: string = `@ ${new Date().getFullYear()} Wetmedyk. All rights reserved.`;
  const locations: Location[] = useSelector(getLocations);

  return (
    <footer>
      <Container className={styles.footer}>
        <div className={styles.footerWrapper}>
          <Row>
            <Col md={4} className={styles.footerWrapperLogo}>
              <img src={logo} alt="" />
            </Col>
            {locations.map((location: Location) => (
              <Col key={location.id} md={4} className={styles.footerWrapperLocations}>
                <div className={styles.location}>
                  <h3>{location.name}</h3>
                  <span>
                    {location.street}, {location.zipCode} {location.city}
                  </span>
                  <span>{location.phone}</span>
                </div>
              </Col>
            ))}
          </Row>
        </div>
        <span className={styles.copyrights}>{copyrights}</span>
      </Container>
      <div className={styles.goBack}>
        <CustomExpandLess disabled={false} handleClick={() => scrollToElement('home')} />
      </div>
    </footer>
  );
};

export default Footer;
