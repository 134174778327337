import { RootState } from '../index';
import { PrimaryServiceCard } from '../../models';

export const getPrimaryServices = ({ service: { services } }: RootState) => services;
export const getIsLoading = ({ service: { isLoading } }: RootState) => isLoading;
export const getServiceError = ({ service: { errorMessage } }: RootState): string | null =>
  errorMessage;
export const getSelectedService = (state: RootState): PrimaryServiceCard | null =>
  state.service.services.find(
    (service: PrimaryServiceCard) => service.id === state.service.selected
  ) || null;
