import { DropdownItem } from '../shared/widgets/dropdown-input/dropdown.component';
import { Location } from '../models';

export const locationToDropdownItem = ({ id, name }: Location): DropdownItem => ({
  name,
  value: id
});

export const postStatusesToDropdownItem = ([name, value]: string[]): DropdownItem => ({
  name,
  value
});
