import { AuthToken, Login } from '../../models';

export enum AuthActions {
  // eslint-disable-next-line no-unused-vars
  LogIn = '[Auth] LogIn',
  // eslint-disable-next-line no-unused-vars
  LogInSuccess = '[Auth] LogIn Success',
  // eslint-disable-next-line no-unused-vars
  LogInError = '[Auth] LogIn Error',
  // eslint-disable-next-line no-unused-vars
  LogOut = '[Auth] LogOut',
  // eslint-disable-next-line no-unused-vars
  LogOutSuccess = '[Auth] LogOut Success'
}

export interface AuthActionsTypes {
  type: AuthActions;
  payload?: AuthToken | { userName: string; password: string } | string;
}

export const LogIn = (payload: Login): AuthActionsTypes => ({
  payload,
  type: AuthActions.LogIn
});

export const LogInSuccess = (payload: AuthToken): AuthActionsTypes => ({
  payload,
  type: AuthActions.LogInSuccess
});

export const LogInErrorAction = (payload: string): AuthActionsTypes => ({
  payload,
  type: AuthActions.LogInError
});

export const LogOutAction = (): AuthActionsTypes => ({
  type: AuthActions.LogOut
});

export const LogOutSuccessAction = (): AuthActionsTypes => ({
  type: AuthActions.LogOutSuccess
});
