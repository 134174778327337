import { User } from '../../wet-manager/models/user.interface';

export enum UsersActions {
  // eslint-disable-next-line no-unused-vars
  LoadUsers = '[Users] Load Users',
  // eslint-disable-next-line no-unused-vars
  AddUsers = '[Users] Add Users',
  // eslint-disable-next-line no-unused-vars
  AddUser = '[Users] Add User',
  // eslint-disable-next-line no-unused-vars
  AddUserSuccess = '[Users] Add User Success',
  // eslint-disable-next-line no-unused-vars
  AddUserError = '[Users] Add User Error',
  // eslint-disable-next-line no-unused-vars
  DeleteUser = '[Users] Delete User',
  // eslint-disable-next-line no-unused-vars
  DeleteUserSuccess = '[Users] Delete User Success'
}

export interface UsersActionsTypes {
  type: UsersActions;
  payload?: User | User[] | number | null | string;
}

export const LoadUsers = (): UsersActionsTypes => ({
  type: UsersActions.LoadUsers
});

export const AddUsers = (payload: User[]): UsersActionsTypes => ({
  payload,
  type: UsersActions.AddUsers
});

export const AddUser = (payload: User): UsersActionsTypes => ({
  payload,
  type: UsersActions.AddUser
});

export const AddUserSuccess = (payload: User): UsersActionsTypes => ({
  payload,
  type: UsersActions.AddUserSuccess
});

export const AddUserError = (payload: string): UsersActionsTypes => ({
  payload,
  type: UsersActions.AddUserError
});

export const DeleteUser = (payload: number): UsersActionsTypes => ({
  payload,
  type: UsersActions.DeleteUser
});

export const DeleteUserSuccess = (payload: number): UsersActionsTypes => ({
  payload,
  type: UsersActions.DeleteUserSuccess
});
