export enum FileActions {
  // eslint-disable-next-line no-unused-vars
  LoadFiles = '[File] Load Files',
  // eslint-disable-next-line no-unused-vars
  AddFiles = '[File] Add Files',
  // eslint-disable-next-line no-unused-vars
  AddFile = '[File] Add File',
  // eslint-disable-next-line no-unused-vars
  AddFileSuccess = '[File] Add File Success',
  // eslint-disable-next-line no-unused-vars
  DeleteFile = '[File] Delete File',
  // eslint-disable-next-line no-unused-vars
  DeleteFileSuccess = '[File] Delete File Success',
  // eslint-disable-next-line no-unused-vars
  FetchError = '[File] Fetch Error'
}

export interface FileActionsTypes {
  type: FileActions;
  payload?: string | null | string[] | File;
}

export const LoadFiles = (): FileActionsTypes => ({
  type: FileActions.LoadFiles
});

export const AddFiles = (payload: string[]): FileActionsTypes => ({
  payload,
  type: FileActions.AddFiles
});

export const DeleteFile = (payload: string): FileActionsTypes => ({
  payload,
  type: FileActions.DeleteFile
});

export const DeleteFileSuccess = (payload: string): FileActionsTypes => ({
  payload,
  type: FileActions.DeleteFileSuccess
});

export const FetchError = (payload: string): FileActionsTypes => ({
  payload,
  type: FileActions.FetchError
});

export const AddFile = (payload: File): FileActionsTypes => ({
  payload,
  type: FileActions.AddFile
});

export const AddFileSuccess = (payload: string): FileActionsTypes => ({
  payload,
  type: FileActions.AddFileSuccess
});
