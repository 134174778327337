import React from 'react';
import logo from '../../images/logo.png';
import classes from './header.module.scss';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Route } from '../../models';
import { routes } from '../../static/routes';
import { scrollToElement } from '../../utils';

const Header = () => {
  return (
    <Navbar className={classes.navbar} collapseOnSelect expand="lg" id="home">
      <Container className={classes.navbarWrapper}>
        <Navbar.Brand>
          <img className={classes.logo} src={logo} alt="wetmedyk-logo" />
        </Navbar.Brand>
        <Navbar.Toggle className={classes.toggle} aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className={['mr-auto', classes.navbarWrapperItems].join(' ')}>
            {routes.map((route: Route) => (
              <Nav.Link
                key={route.path}
                className={classes.navbarWrapperItemsItem}
                onClick={() => scrollToElement(route.path)}>
                {route.displayName}
              </Nav.Link>
            ))}
            <NavDropdown title="umów się na wizytę" id="basic-nav-dropdown">
              <NavDropdown.Item
                className={classes.navbarWrapperItemsItemDropdown}
                href="http://www.wettermin.pl/23893"
                target="_blank"
                rel="noreferrer">
                Przychodnia WetMedyk Banino
              </NavDropdown.Item>
              <NavDropdown.Item
                className={classes.navbarWrapperItemsItemDropdown}
                href="http://www.wettermin.pl/43893"
                target="_blank"
                rel="noreferrer">
                Gabinet WetMedyk Pępowo
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
