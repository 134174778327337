import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { LocalHospital } from '@material-ui/icons';

import classes from './services.module.scss';
import '../../scss/_utilities.scss';

import Contact from '../components/contact.component';
import Button from '../../shared/button/button.component';

import { Location } from '../../models';
import { SelectLocation, getLocations, getSelectedLocation } from '../../store/locations-store';

const Services = () => {
  const dispatch = useDispatch();
  const selectedLocation = useSelector(getSelectedLocation);
  const locations = useSelector(getLocations);
  const handleSelection = (id: number) => {
    if (!selectedLocation || id !== (selectedLocation as Location).id) {
      dispatch(SelectLocation(id));
    }
  };

  return (
    <section id="contact" className={[classes.services, 'sectionPadding'].join(' ')}>
      <h2>Kontakt</h2>
      <Container className={classes.servicesWrapper}>
        {locations.map(({ id, name }: Location) => (
          <Button
            type="button"
            text={name}
            key={id}
            classes={[classes.service]}
            onClick={() => handleSelection(id)}>
            <LocalHospital />
          </Button>
        ))}
      </Container>
      {selectedLocation ? <Contact {...selectedLocation} /> : <h3>Nie wybrano żadnej lecznicy</h3>}
    </section>
  );
};

export default Services;
