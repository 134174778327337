import { PrimaryServiceCard } from '../../models';

export enum ServiceActions {
  // eslint-disable-next-line no-unused-vars
  LoadServices = '[Services] Load Services',
  // eslint-disable-next-line no-unused-vars
  AddServices = '[Services] Add Services',
  // eslint-disable-next-line no-unused-vars
  AddService = '[Services] Add Service',
  // eslint-disable-next-line no-unused-vars
  AddServiceSuccess = '[Services] Add Service Success',
  // eslint-disable-next-line no-unused-vars
  DeleteService = '[Services] Delete Service',
  // eslint-disable-next-line no-unused-vars
  DeleteServiceSuccess = '[Services] Delete Service Success',
  // eslint-disable-next-line no-unused-vars
  UpdateService = '[Services] Update Service',
  // eslint-disable-next-line no-unused-vars
  UpdateServiceSuccess = '[Services] Update Service Success',
  // eslint-disable-next-line no-unused-vars
  SelectService = '[Services] Select Service'
}

export interface ServiceActionsTypes {
  type: ServiceActions;
  payload?: PrimaryServiceCard | PrimaryServiceCard[] | number | null;
}

export const LoadServices = (): ServiceActionsTypes => ({
  type: ServiceActions.LoadServices
});

export const AddServices = (payload: PrimaryServiceCard[]): ServiceActionsTypes => ({
  payload,
  type: ServiceActions.AddServices
});

export const AddService = (payload: PrimaryServiceCard): ServiceActionsTypes => ({
  payload,
  type: ServiceActions.AddService
});

export const AddServiceSuccess = (payload: PrimaryServiceCard): ServiceActionsTypes => ({
  payload,
  type: ServiceActions.AddServiceSuccess
});

export const UpdateService = (payload: PrimaryServiceCard): ServiceActionsTypes => ({
  payload,
  type: ServiceActions.UpdateService
});

export const UpdateServiceSuccess = (payload: PrimaryServiceCard): ServiceActionsTypes => ({
  payload,
  type: ServiceActions.UpdateServiceSuccess
});

export const DeleteService = (payload: number): ServiceActionsTypes => ({
  payload,
  type: ServiceActions.DeleteService
});

export const DeleteServiceSuccess = (payload: number): ServiceActionsTypes => ({
  payload,
  type: ServiceActions.DeleteServiceSuccess
});

export const SelectService = (payload: number | null): ServiceActionsTypes => ({
  payload,
  type: ServiceActions.SelectService
});
