import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import * as _ from 'lodash';

import classes from './employees.module.scss';
import '../../scss/_utilities.scss';

import { Employee } from '../../models';

import EmployeeCard from '../components/employee.component';

import { getEmployees, getIsLoading, LoadEmployees } from '../../store/employees-store';

const Employees = () => {
  const dispatch = useDispatch();
  const employees = useSelector(getEmployees);
  const isLoading = useSelector(getIsLoading);
  const { heading } = {
    heading: 'O nas'
  };

  useEffect(() => {
    if (!employees.length && !isLoading) {
      dispatch(LoadEmployees());
    }
  }, [dispatch, employees, isLoading]);

  return (
    <section id={'employees'} className={['sectionPadding', classes.employees].join(' ')}>
      <Container className={classes.employeesContainer}>
        <div className={classes.title}>
          <h2>{heading}</h2>
        </div>
        <div className={classes.employeesContainerWrapper}>
          {_.sortBy(employees, 'order').map((employee: Employee) => (
            <EmployeeCard key={employee.id} employee={employee} />
          ))}
        </div>
      </Container>
    </section>
  );
};

export default Employees;
