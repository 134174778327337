import { Post } from '../../models';

export enum PostActions {
  // eslint-disable-next-line no-unused-vars
  LoadPosts = '[Posts] Load Posts',
  // eslint-disable-next-line no-unused-vars
  AddPosts = '[Posts] Add Posts',
  // eslint-disable-next-line no-unused-vars
  SetSelectedPost = '[Posts] Set Selected Post',
  // eslint-disable-next-line no-unused-vars
  UpdatePost = '[Posts] Update Post',
  // eslint-disable-next-line no-unused-vars
  UpdatePostSuccess = '[Posts] Update Post Success',
  // eslint-disable-next-line no-unused-vars
  AddPost = '[Posts] Add Post',
  // eslint-disable-next-line no-unused-vars
  AddPostSuccess = '[Posts] Add Post Success',
  // eslint-disable-next-line no-unused-vars
  DeletePost = '[Posts] Delete Post',
  // eslint-disable-next-line no-unused-vars
  DeletePostSuccess = '[Posts] Delete Post Success'
}

export interface PostActionsTypes {
  type: PostActions;
  payload?: Post | Partial<Post> | Post[] | number | null;
}

export const LoadPosts = (): PostActionsTypes => ({
  type: PostActions.LoadPosts
});

export const AddPosts = (payload: Post[]): PostActionsTypes =>
  ({
    payload,
    type: PostActions.AddPosts
  } as {
    payload: Post[];
    type: PostActions;
  });

export const SetSelectedPost = (payload: number | null): PostActionsTypes => ({
  payload,
  type: PostActions.SetSelectedPost
});

export const UpdatePost = (payload: Post): PostActionsTypes => ({
  payload,
  type: PostActions.UpdatePost
});

export const UpdatePostSuccess = (payload: Post): PostActionsTypes => ({
  payload,
  type: PostActions.UpdatePostSuccess
});

export const AddPost = (payload: Partial<Post>): PostActionsTypes => ({
  payload,
  type: PostActions.AddPost
});

export const AddPostSuccess = (payload: Post): PostActionsTypes => ({
  payload,
  type: PostActions.AddPostSuccess
});

export const DeletePost = (payload: number): PostActionsTypes => ({
  payload,
  type: PostActions.DeletePost
});

export const DeletePostSuccess = (payload: number): PostActionsTypes => ({
  payload,
  type: PostActions.DeletePostSuccess
});
