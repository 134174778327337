import { RootState } from '../index';
import { Post } from '../../models';

export const getPosts = ({ post: { posts } }: RootState): Post[] => posts;
export const getPublishedPosts = ({ post: { posts } }: RootState): Post[] =>
  posts.filter((post: Post) => post.status === 'published');
export const getIsLoading = ({ post: { isLoading } }: RootState): boolean => isLoading;
export const getSelectedPost = (state: RootState): Post | null =>
  state.post.posts.find((post: Post) => post.id === state.post.selected) || null;
export const getPostError = ({ post: { errorMessage } }: RootState): string | null => errorMessage;
