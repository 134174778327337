import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';

import styles from './primary-services.module.scss';

import { PrimaryServiceCard } from '../../models';
import { RootState } from '../../store';

import PrimaryService from '../components/primary-service.component';
import Dropdown from '../../shared/widgets/dropdown-input/dropdown.component';

import { LoadServices } from '../../store/services-store';
import { OpenModal, ModalState } from '../../store/modal-store';
import { getLocations, getSelectedLocationId, SelectLocation } from '../../store/locations-store';

import { locationToDropdownItem } from '../../utils';

const PrimaryServices = () => {
  const dispatch = useDispatch();
  const primaryServices = useSelector((state: RootState) => state.service.services);
  const isLoading = useSelector((state: RootState) => state.service.isLoading);
  const selectedLocation = useSelector(getSelectedLocationId);
  const locations = useSelector(getLocations);
  const [displayServices, setDisplayServices] = useState<PrimaryServiceCard[]>([]);

  const handleChange = (event: { currentTarget: { value: unknown } }) => {
    dispatch(SelectLocation(event.currentTarget.value as any));
  };

  const openModal = (id: number | null = null) => {
    const modalData: ModalState<PrimaryServiceCard> = {
      data: primaryServices.find((service) => service.id === id) as PrimaryServiceCard,
      shouldDisplay: true,
      contentType: 'service'
    };
    dispatch(OpenModal(modalData));
  };

  useEffect(() => {
    if (!primaryServices.length && !isLoading) {
      dispatch(LoadServices());
    }
  }, [dispatch, isLoading, primaryServices]);

  useEffect(() => {
    const selectedServices =
      selectedLocation === null
        ? primaryServices
        : primaryServices.filter((card: PrimaryServiceCard) =>
            card.available.find((id: number) => id === selectedLocation)
          );
    setDisplayServices(selectedServices);
  }, [selectedLocation, primaryServices]);

  return (
    <section id="services" className={[styles.primaryServices, 'sectionPadding'].join(' ')}>
      <h2>Usługi</h2>
      <Container>
        <Dropdown
          styling="custom"
          value={selectedLocation ? selectedLocation : 1}
          items={locations.map((location) => locationToDropdownItem(location))}
          onChange={handleChange}
        />
      </Container>
      <Container>
        <div className={styles.primaryServicesWrapper}>
          {_.sortBy(displayServices, 'order').map((service: PrimaryServiceCard) => (
            <PrimaryService
              key={service.id}
              {...service}
              handleClick={() => openModal(service.id)}
            />
          ))}
        </div>
      </Container>
    </section>
  );
};

export default PrimaryServices;
