import { RootState } from '../index';
import { Employee } from '../../models';

export const getEmployees = ({ employee: { employees } }: RootState): Employee[] => employees;
export const getIsLoading = ({ employee: { isLoading } }: RootState): boolean => isLoading;
export const getSelectedEmployee = (state: RootState): Employee | null =>
  state.employee.employees.find((employee: Employee) => employee.id === state.employee.selected) ||
  null;
export const getEmployeeError = ({ employee: { errorMessage } }: RootState): string | null =>
  errorMessage;
