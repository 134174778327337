import { RootState } from '../index';
import { Location } from '../../models';
import { createSelector } from 'reselect';

export const getLocations = ({ location: { locations } }: RootState): Location[] => locations;
export const getIsLoading = ({ location: { isLoading } }: RootState): boolean => isLoading;
export const getSelectedLocationId = ({ location: { selected } }: RootState): number | null =>
  selected;
export const getSelectedLocation = createSelector(
  [getSelectedLocationId, getLocations],
  (id: number | null, locations: Location[]): Location | null =>
    !id ? null : locations.find((location: Location) => location.id === id) || null
);
export const getLocationError = ({ location: { errorMessage } }: RootState): string | null =>
  errorMessage;
