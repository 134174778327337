import { Location } from '../../models';

export enum LocationActions {
  // eslint-disable-next-line no-unused-vars
  LoadLocations = '[Locations] Load Locations',
  // eslint-disable-next-line no-unused-vars
  AddLocations = '[Locations] Add Locations',
  // eslint-disable-next-line no-unused-vars
  AddLocation = '[Locations] Add Location',
  // eslint-disable-next-line no-unused-vars
  AddLocationSuccess = '[Locations] Add Location Success',
  // eslint-disable-next-line no-unused-vars
  UpdateLocation = '[Locations] Update Location',
  // eslint-disable-next-line no-unused-vars
  UpdateLocationSuccess = '[Locations] Update Location Success',
  // eslint-disable-next-line no-unused-vars
  DeleteLocation = '[Locations] Delete Location',
  // eslint-disable-next-line no-unused-vars
  DeleteLocationSuccess = '[Locations] Delete Location Success',
  // eslint-disable-next-line no-unused-vars
  SelectLocation = '[Locations] Select Locations'
}

export interface LocationActionsTypes {
  type: LocationActions;
  payload?: Location | Location[] | number | null;
}

export const LoadLocations = (): LocationActionsTypes => ({
  type: LocationActions.LoadLocations
});

export const AddLocations = (payload: Location[]): LocationActionsTypes =>
  ({
    payload,
    type: LocationActions.AddLocations
  } as {
    payload: Location[];
    type: LocationActions;
  });

export const SelectLocation = (payload: number | null): LocationActionsTypes => ({
  payload,
  type: LocationActions.SelectLocation
});

export const DeleteLocation = (payload: number): LocationActionsTypes => ({
  payload,
  type: LocationActions.DeleteLocation
});

export const DeleteLocationSuccess = (payload: number): LocationActionsTypes => ({
  payload,
  type: LocationActions.DeleteLocationSuccess
});

export const AddLocation = (payload: Location): LocationActionsTypes => ({
  payload,
  type: LocationActions.AddLocation
});

export const AddLocationSuccess = (payload: Location): LocationActionsTypes => ({
  payload,
  type: LocationActions.AddLocationSuccess
});

export const UpdateLocation = (payload: Location): LocationActionsTypes => ({
  payload,
  type: LocationActions.UpdateLocation
});

export const UpdateLocationSuccess = (payload: Location): LocationActionsTypes => ({
  payload,
  type: LocationActions.UpdateLocationSuccess
});
