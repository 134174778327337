import React from 'react';

import { ReactComponent as Veterinary } from '../../images/veterinary.svg';
import { ReactComponent as Stethoscope } from '../../images/stethoscope.svg';
import { ReactComponent as Health } from '../../images/health.svg';
import { ReactComponent as HeartBeat } from '../../images/heartbeat.svg';
import { ReactComponent as Tooth } from '../../images/tooth.svg';
import { ReactComponent as Vaccine } from '../../images/vaccine.svg';
import { ReactComponent as MedicalReport } from '../../images/medical-report.svg';
import { ReactComponent as Hospital } from '../../images/animal.svg';
import { ReactComponent as PetHouse } from '../../images/pet-house.svg';
import { ReactComponent as Bathing } from '../../images/bathing.svg';
import { ReactComponent as Paw } from '../../images/paw.svg';

import classes from './services-icon.module.scss';

export type WetIcons =
  | 'Veterinary'
  | 'Stethoscope'
  | 'Health'
  | 'HeartBeat'
  | 'Tooth'
  | 'Vaccine'
  | 'MedicalReport'
  | 'Hospital'
  | 'PetHouse'
  | 'Bathing'
  | 'Paw';

const iconsFactory: Record<WetIcons, any> = {
  Paw: Paw,
  Veterinary: Veterinary,
  Stethoscope: Stethoscope,
  Health: Health,
  HeartBeat: HeartBeat,
  Tooth: Tooth,
  Vaccine: Vaccine,
  MedicalReport: MedicalReport,
  Hospital: Hospital,
  PetHouse: PetHouse,
  Bathing: Bathing
};

export const ICON_NAMES = Object.keys(iconsFactory);

const ServicesIcon = ({ name }: { name: WetIcons }) => {
  const Icon = iconsFactory[name];

  return <Icon className={classes.icon} />;
};

export default ServicesIcon;
