import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';

import auth from './auth-store/reducer';
import employee from './employees-store/reducer';
import location from './locations-store/reducer';
import post from './posts-store/reducer';
import service from './services-store/reducer';
import modal from './modal-store/reducer';
import file from './files-store/reducer';
import user from './users-store/reducer';

import { loginUser$, logoutUser$, AuthState } from './auth-store';
import {
  addEmployee$,
  deleteEmployee$,
  loadEmployees$,
  updateEmployee$,
  EmployeeState
} from './employees-store';
import {
  addLocation$,
  deleteLocation$,
  loadLocations$,
  updateLocation$,
  LocationState
} from './locations-store';
import { addPost$, deletePost$, loadPosts$, updatePost$, PostState } from './posts-store';
import {
  addService$,
  deleteService$,
  loadServices$,
  updateService$,
  ServiceState
} from './services-store';
import { ModalState } from './modal-store';
import { addFile$, deleteFile$, loadFiles$, FileState } from './files-store';
import { addUser$, deleteUser$, loadUsers$, UsersState } from './users-store';

export default combineReducers({
  auth,
  employee,
  location,
  post,
  service,
  modal,
  file,
  user
});

export const rootEpic = combineEpics(
  loginUser$,
  logoutUser$,
  loadEmployees$,
  deleteEmployee$,
  loadLocations$,
  deleteLocation$,
  updateLocation$,
  addLocation$,
  loadPosts$,
  updatePost$,
  loadServices$,
  addPost$,
  deletePost$,
  addEmployee$,
  updateEmployee$,
  loadFiles$,
  deleteFile$,
  addFile$,
  loadUsers$,
  addUser$,
  deleteUser$,
  addService$,
  updateService$,
  deleteService$
);

export interface RootState {
  employee: EmployeeState;
  auth: AuthState;
  location: LocationState;
  post: PostState;
  service: ServiceState;
  modal: ModalState<any>;
  file: FileState;
  user: UsersState;
}

export interface FeatureState {
  isLoading: boolean;
  isSaving: boolean;
  errorMessage: string | null;
  selected: number | null | string;
}
