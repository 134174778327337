import React from 'react';
import { MdExpandLess } from 'react-icons/all';
import { Fab } from '@material-ui/core';
import styles from './control-arrows.module.scss';

interface Props {
  disabled: boolean;
  handleClick: any;
}

const CustomExpandLess = ({ disabled, handleClick }: Props) => (
  <Fab
    className={[styles.noOutline, styles.topMargin].join(' ')}
    aria-label="left"
    disabled={disabled}>
    <MdExpandLess size={'40px'} onClick={handleClick} />
  </Fab>
);

export default CustomExpandLess;
