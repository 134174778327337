import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';

import classes from './news.module.scss';
import '../../scss/_utilities.scss';

import PostCard from '../components/post-card.component';

import { mapPostToModalItem, ModalItem, Post } from '../../models';

import { LoadPosts, getIsLoading, getPublishedPosts } from '../../store/posts-store';
import { ModalState, OpenModal } from '../../store/modal-store';

const News = () => {
  const news = useSelector(getPublishedPosts);
  const isLoading = useSelector(getIsLoading);
  const dispatch = useDispatch();
  const heading = 'Aktualności';
  const openModal = (id: number) => {
    const modalData: ModalState<ModalItem> = {
      data: mapPostToModalItem(news.find((post: Post) => post.id === id) as Post),
      contentType: 'post',
      shouldDisplay: true
    };
    dispatch(OpenModal(modalData));
  };

  useEffect(() => {
    if (!news.length && !isLoading) {
      dispatch(LoadPosts());
    }
  }, [dispatch, news, isLoading]);

  return (
    <section id={'news'} className={classes.news}>
      <h2>{heading}</h2>
      <div className={classes.newsContainer}>
        {_.sortBy(news, 'id')
          .reverse()
          .map((post: Post) => (
            <PostCard key={post.id} post={post} onClick={openModal} />
          ))}
      </div>
    </section>
  );
};

export default News;
