import React from 'react';

import classes from './employee.module.scss';

import { Employee } from '../../models';

const EmployeeCard = ({ employee }: { employee: Employee }) => (
  <div className={classes.person}>
    <img src={employee.photo} alt={employee.lastName} />
    <h3>
      {employee.firstName} {employee.lastName}
    </h3>
    <h4>{employee.title}</h4>
  </div>
);

export default EmployeeCard;
