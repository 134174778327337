import { AuthState, INITIAL_STATE, AuthActions, AuthActionsTypes } from './index';
import { AuthToken } from '../../models';

export default function authReducer(state: AuthState = INITIAL_STATE, action: AuthActionsTypes) {
  switch (action.type) {
    case AuthActions.LogInSuccess:
      return {
        ...state,
        user: {
          ...(action.payload as AuthToken)
        },
        errorMessage: null
      };

    case AuthActions.LogOutSuccess:
      return {
        ...state,
        user: {}
      };
    case AuthActions.LogInError:
      return {
        ...state,
        errorMessage: action.payload
      };

    default:
      return state;
  }
}
