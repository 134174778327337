import { Employee } from '../../models';

export enum EmployeeActions {
  // eslint-disable-next-line no-unused-vars
  Load = '[Employees] Load Employees',
  // eslint-disable-next-line no-unused-vars
  AddEmployee = '[Employees] Add Employee',
  // eslint-disable-next-line no-unused-vars
  AddEmployeeSuccess = '[Employees] Add Employee Success',
  // eslint-disable-next-line no-unused-vars
  AddEmployees = '[Employees] Add Employees',
  // eslint-disable-next-line no-unused-vars
  UpdateEmployee = '[Employees] Update Employee',
  // eslint-disable-next-line no-unused-vars
  UpdateEmployeeSuccess = '[Employees] Update Employee Success',
  // eslint-disable-next-line no-unused-vars
  DeleteEmployee = '[Employees] Delete Employee',
  // eslint-disable-next-line no-unused-vars
  DeleteEmployeeSuccess = '[Employees] Delete Employee Success',
  // eslint-disable-next-line no-unused-vars
  SelectEmployee = '[Employees] Set Selected Employee',
  // eslint-disable-next-line no-unused-vars
  EmployeeError = '[Employees] Employee Error'
}

export interface EmployeeActionsTypes {
  type: EmployeeActions;
  payload?: Employee | number | Employee[] | null | string;
}

export const LoadEmployees = (): EmployeeActionsTypes => ({
  type: EmployeeActions.Load
});

export const AddEmployees = (payload: Employee[]): EmployeeActionsTypes =>
  ({
    payload,
    type: EmployeeActions.AddEmployees
  } as {
    payload: Employee[];
    type: EmployeeActions;
  });

export const DeleteEmployee = (payload: number): EmployeeActionsTypes =>
  ({
    payload,
    type: EmployeeActions.DeleteEmployee
  } as {
    payload: number;
    type: EmployeeActions;
  });

export const DeleteEmployeeSuccess = (payload: number): EmployeeActionsTypes =>
  ({
    payload,
    type: EmployeeActions.DeleteEmployeeSuccess
  } as {
    payload: number;
    type: EmployeeActions;
  });

export const SelectEmployee = (payload: number | null): EmployeeActionsTypes => ({
  payload,
  type: EmployeeActions.SelectEmployee
});

export const AddEmployee = (payload: Employee): EmployeeActionsTypes => ({
  payload,
  type: EmployeeActions.AddEmployee
});

export const UpdateEmployee = (payload: Employee): EmployeeActionsTypes => ({
  payload,
  type: EmployeeActions.UpdateEmployee
});

export const AddEmployeeSuccess = (payload: Employee): EmployeeActionsTypes => ({
  payload,
  type: EmployeeActions.AddEmployeeSuccess
});

export const UpdateEmployeeSuccess = (payload: Employee): EmployeeActionsTypes => ({
  payload,
  type: EmployeeActions.UpdateEmployeeSuccess
});

export const EmployeeError = (payload: string): EmployeeActionsTypes => ({
  payload,
  type: EmployeeActions.EmployeeError
});
