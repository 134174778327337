import { ModalState } from './index';

export enum ModalActions {
  // eslint-disable-next-line no-unused-vars
  OpenModal = '[Modal] Open Modal',
  // eslint-disable-next-line no-unused-vars
  CloseModal = '[Modal] Close Modal'
}

export interface ModalActionsTypes<T> {
  type: ModalActions;
  payload?: ModalState<T>;
}

export const OpenModal = <T>(payload: ModalState<T>): ModalActionsTypes<T> => ({
  payload,
  type: ModalActions.OpenModal
});

export const CloseModal = (): ModalActionsTypes<null> => ({
  type: ModalActions.CloseModal
});
