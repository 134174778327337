import React, { ChangeEvent } from 'react';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { InputLabel, Select } from '@material-ui/core';
import * as _ from 'lodash';

import { useStyles } from './dropdown.styles';
import styles from '../../../wet-manager/forms/form.module.scss';

import { stringToStartCase } from '../../../utils';

export type STYLING_TYPES = 'default' | 'custom';

export interface DropdownItem {
  value: number | string;
  name: string;
}

export interface DropdownProps {
  items: DropdownItem[];
  onChange: any;
  value: string | number;
  styling: STYLING_TYPES;
  label?: string;
}

const Dropdown = ({ items, onChange, value, styling, label }: DropdownProps) => {
  const classes = useStyles();
  const customStyling = styling === 'custom';

  return (
    <>
      {items.length ? (
        <FormControl variant="outlined" className={customStyling ? classes.root : styles.formItem}>
          {label && (
            <InputLabel id="demo-simple-select-disabled-label">{_.startCase(label)}</InputLabel>
          )}
          <Select
            className={customStyling ? classes.select : ''}
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={value}
            classes={customStyling ? { iconOutlined: classes.iconOutlined } : {}}
            onChange={(event: ChangeEvent<{ value: unknown }>) =>
              onChange({ currentTarget: { value: event.target.value } }, label)
            }>
            {items.map(({ value, name }: DropdownItem) => (
              <MenuItem className={customStyling ? classes.size : ''} key={value} value={value}>
                {stringToStartCase(name)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : null}
    </>
  );
};

export default Dropdown;
