import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Carousel } from 'react-bootstrap';

import { Location } from '../../models';

import LocationCard from '../components/location.component';

import { getIsLoading, getLocations, LoadLocations } from '../../store/locations-store';

const Locations = () => {
  const locations = useSelector(getLocations);
  const isLoading = useSelector(getIsLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!locations.length && !isLoading) {
      dispatch(LoadLocations());
    }
  }, [dispatch, locations, isLoading]);

  return (
    <Carousel>
      {locations.length > 0 &&
        locations.map((location: Location) => (
          <Carousel.Item key={location.id}>
            <LocationCard key={location.id} {...location} />
          </Carousel.Item>
        ))}
    </Carousel>
  );
};

export default Locations;
