import { AuthToken } from '../../models';

export interface AuthState {
  user: AuthToken | Partial<AuthToken>;
  errorMessage: string;
}

export const INITIAL_STATE: AuthState = {
  user: {},
  errorMessage: ''
};
