import { ActionsObservable, ofType } from 'redux-observable';
import { catchError, map, switchMap } from 'rxjs/operators';
import { fromPromise } from 'rxjs/internal-compatibility';
import { AxiosResponse } from 'axios';
import { of } from 'rxjs';
import {
  AddEmployees,
  AddEmployeeSuccess,
  DeleteEmployeeSuccess,
  EmployeeActions,
  EmployeeActionsTypes,
  EmployeeError,
  UpdateEmployeeSuccess
} from './actions';
import axiosInstance from '../../services/interceptor';
import { CURRENT_ENV } from '../../environment';
import { Employee } from '../../models';

const baseUrl = `${CURRENT_ENV}/employees`;

export const loadEmployees$ = (action$: ActionsObservable<EmployeeActionsTypes>) =>
  action$.pipe(
    ofType(EmployeeActions.Load),
    switchMap(() =>
      fromPromise(axiosInstance.get(baseUrl)).pipe(
        map((res: AxiosResponse<Employee[]>) => AddEmployees(res.data)),
        catchError((error: Error) => of(EmployeeError(error.message)))
      )
    )
  );

export const deleteEmployee$ = (action$: ActionsObservable<EmployeeActionsTypes>) =>
  action$.pipe(
    ofType(EmployeeActions.DeleteEmployee),
    switchMap((action) =>
      fromPromise(axiosInstance.delete(`${baseUrl}/${action.payload}`)).pipe(
        map((res: AxiosResponse<Employee>) => DeleteEmployeeSuccess(res.data.id))
      )
    )
  );

export const addEmployee$ = (action$: ActionsObservable<EmployeeActionsTypes>) =>
  action$.pipe(
    ofType(EmployeeActions.AddEmployee),
    switchMap((action) =>
      fromPromise(axiosInstance.post(baseUrl, action.payload)).pipe(
        map((res: AxiosResponse<Employee>) => AddEmployeeSuccess(res.data))
      )
    )
  );

export const updateEmployee$ = (action$: ActionsObservable<EmployeeActionsTypes>) =>
  action$.pipe(
    ofType(EmployeeActions.UpdateEmployee),
    switchMap((action) => {
      const { id } = action.payload as Employee;
      return fromPromise(axiosInstance.put(`${baseUrl}/${id}`, action.payload)).pipe(
        map((res: AxiosResponse<Employee>) => UpdateEmployeeSuccess(res.data))
      );
    })
  );
